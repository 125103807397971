@import '../../../../variables';

.door, .doorUnlock, .doorButtonHide {
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding: 7.5px;
    text-align: center;
    appearance: none;
    color: $grey;
    margin: 5px 0px;
    border-radius: 33px;
    position: relative;
    outline: none;

}

@mixin door-initial-style {
    border-radius: 50%;
    background: white;
    z-index: 1;
    width: 48px;
    height: 48px;
    position: relative;
    background-image: url('../../../../assets/images/solid/lock-alt-grey.svg');
    background-position: 50% 50%;
    background-size: 36px;
    background-repeat: no-repeat;
    border: none;
}


.door {
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        @include door-initial-style
    }

    &::-moz-range-thumb {
        @include door-initial-style
    }
}

@mixin door-unlock-initial-style {
    border-radius: 50%;
    background: white;
    z-index: 1;
    width: 48px;
    height: 48px;
    position: relative;
    background-image: url('../../../../assets/images/solid/lock-alt-open-green.svg');
    background-position: 50% 50%;
    background-size: 36px;
    background-repeat: no-repeat;
    border: none;
}

.doorUnlock {
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        @include door-unlock-initial-style;
    }

    &::-moz-range-thumb {
        @include door-unlock-initial-style;
    }
}

.doorUnlockWait {
    border-radius: 50%;
    z-index: 1;
    width: 34px;
    height: 34px;
    position: absolute;
    background-image: url('../../../../assets/images/solid/lock-alt-open-green.svg');
    background-position: 50% 50%;
    background-size: 36px;
    background-repeat: no-repeat;
    border: none;
    left: 8px;
    top: 6px;
}

.doorWaitMessage {
    line-height: 45px;
    color: $primary;
    font-size: 16px;
    text-align: center;
    padding-left: 25%;
    font-weight: 400;
    text-align: center;
}

.doorButtonHide::-webkit-slider-thumb {
    background: none;
    display: none;
}

#doorUnlockMessage {
    color: $primary;
}

.doorContainer:before {
    content: attr(data-content);
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 1.45rem;
    font-size: 1rem;
    z-index: 1;
    color: $grey-dark;
    margin: 0px 20px 0 70px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.doorContainer {
    position: relative; 
    max-width: 324px;
}


.door:before, .doorUnlock:before   {
    content: attr(data-content);
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    bottom: 0;
    z-index: 1;
    font-size: 22px;
}



input[type=range]::-webkit-slider-thumb:before {
    background: green;
}

.doorCancel {
    border-radius: 50%;
    background: $grey-dark;
    width: 48px;
    height: 48px;
    position: relative;
    background-image: url('../../../../assets/images/lined/close.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    // margin-left: 24px;
    
    path {
        fill: $white;
    }
}

.doorConfirm {
    border-radius: 50%;
    background: $primary;
    width: 48px;
    height: 48px;
    position: relative;
    background-image: url('../../../../assets/images/lined/ok.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin-right: 10px;
    
    path {
        fill: $white;
    }
}


input[type=range]::-moz-focus-outer {
    border: 0;
}


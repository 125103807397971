@import '../../variables';

.skipButton {
    width: 120px;
    right: 15px;
    text-transform: capitalize;
    background: transparent;
    height: 45px;
    outline: none;
    border: none;
    font-weight: 100;
    color: #6C727E;
    margin-top: 4px;
    cursor: pointer;
}

.nextButton {
    width: 120px;
    text-transform: capitalize;
    background: $secondary;
    height: 45px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
}

.gridItemSelected {
    background: $secondary !important;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 8px);
    grid-column-gap: 5px;
    height: 48px;
    margin: auto;
    justify-content: center;
}

.gridItem {
    height: 8px;
    width: 8px;
    background: $grey-light;
    line-height: 8px;
    border-radius: 50%;
    margin: 20px 0px;
}
@import '../../variables';

///// copied from property css

.titlePaddingTop {
  // padding-top: 32px !important;
  padding: 5px 0px;
  margin: 32px 0 0 0;
}

.header {
  display: block;
  background-color: #FFFFFF;
  padding: 25px;
  padding-top: 0px;
  border-radius: 20px 20px 0px 0px;
}

.card {
  background: #fff;
  margin-top: 20px;
  padding: 24px;
  border-radius: 20px;
  box-shadow: 0 7px 20px 0 rgba(0,0,0,0.08);
  padding-top: 1px;
}

@media only screen and (min-width: 900px) {
  .header {
      margin-right: -24px;
      margin-left: -24px;
      margin-top: 20px;
      padding-top: 20px;
      border-radius: 20px;
      display: block;
      background: white;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  }
}


//////// copied from navbar desktop css

.desktopNavbar {
  height: 66px;
  background: #fff;
  padding: 0px 4%;
  line-height: 66px;
}

.logo {
  height: 22px;
  width: 130px;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  // margin-right: 79px;
  cursor: pointer;
}

.tutorialButton {
  // height: 22px;
  width: 130px;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  // margin-right: 79px;
  cursor: pointer;
}

.languageSelect {
  // height: 22px;
  // width: 130px;
  display: inline-block !important;
  vertical-align: middle;
  height: 100%;
  // margin-right: 79px;
  cursor: pointer;
}
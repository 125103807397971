@import '../../../variables';

.card, .cardMini {
    background: #fff;
    margin-top: 20px;
    padding: 24px;
    border-radius: 20px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1)
}
.iconText {
    line-height: 30px; 
    display: inline-block; 
    font-size: 16px;
}

.icon {
    margin-right: 6px;
    height: 26px;
    width: 26px; 
}

.btnEditIcal {
    background-image: url('../../../assets/images/lined/new.svg');
    filter: invert(15%) sepia(89%) saturate(7482%) hue-rotate(332deg) brightness(99%) contrast(105%);
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: 50% 50%;
    width: 34px;
    height: 34px;
    display: block;
    float: right;
    margin-right: 8px;
}

.btnDeleteIcal {
    background-image: url('../../../assets/images/lined/bin.svg');
    filter: invert(15%) sepia(89%) saturate(7482%) hue-rotate(332deg) brightness(99%) contrast(105%);
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: 50% 50%;
    width: 34px;
    height: 34px;
    display: block;
    float: right;
    margin-right: 8px;
}

.lowBattery {

    &Icon {
        width: 32px;
        height: 24px;
        position: relative;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        
        path {
            fill: $warning;
        }
    }

    &Text {
        color: $warning;
    }
}

.boxOffline {
    
    &Icon {
        width: 32px;
        height: 24px;
        position: relative;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        
        path {
            fill: $warning;
        }
    }
}

.linksFlex {
    display: flex;
    flex-direction: row-reverse;
    padding: 0px;
}

.doorContainer {
    margin-top: 32px;
}

.desktopCardContainer {
    margin-top: 32px;
}

.titlePaddingTop {
    padding-top: 0px;
}

.iconName {
    display: none;
}

.iconContainerMargin {
    margin: 0px;
    margin-top: 15px;
}

.cardMini {
    display: flex !important;
}

.propertyMiniDesktop {
    display: none !important;
}

.desktopCardContainer {
    padding: 0px !important;
}

@media only screen and (min-width: 900px) {
    .card {
        background: none;
        box-shadow: none;
        padding: 0px !important;
        margin-top: 32px !important;
    }

    .doorContainer {
        margin-top: 32px;
        margin-bottom: 32px;
        margin-left: -15px;
        margin-right: -15px;
        padding: 24px;
        background: #fff;
        border-radius: 20px;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    }

    .desktopCardContainer {
        height: 726px;
        width: 504px;
        border-radius: 16px;
        padding: 25px !important;
        background-color: #FFFFFF;
        box-shadow: 0 7px 20px 0 rgba(0,0,0,0.08);
        margin-top: 0px;
        max-width: 49% !important;
        margin: 0.5% !important;
    }

    .bookingsCardContainer {
        height: 190px;
        border-radius: 16px;
        padding: 25px !important;
        background-color: #FFFFFF;
        box-shadow: 0 7px 20px 0 rgba(0,0,0,0.08);
    }

    .titlePaddingTop {
        padding-top: 32px !important;
    }

    .btnEditProperty {
        width: 40px;
        height: 40px;
        margin-right: 24px;
    }

    .btnCreateKey {
        width: 40px;
        height: 40px;
    }

    .iconContainer, .iconContainerMargin {
        margin: auto;
    }

    .icon {
        margin-right: 8px;
        height: 32px;
        width: 32px; 
    }

    .iconName {
        display: inline-block;
        text-transform: lowercase;
        margin-left: 5px;
        margin-right: 32px;
    }

    .linksFlex {
        margin: auto;
    }

    .cardMini {
        display: none !important;
    }

    .propertyMiniDesktop {
        display: block !important;
    }
}

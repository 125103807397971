@import '../../variables';

.desktopNavbar {
    height: 66px;
    background: #fff;
    padding: 0px 48px;
    line-height: 66px;
}

.desktopNavbarBilling {
  border: none;
  height: 66px;
}

.desktopNavbarButton {
  height: 22px;
  width: 130px;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  margin-right: 32px;
  margin-top: 12px;
}

.logo {
  height: 22px;
  width: 130px;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  margin-right: 79px;
  cursor: pointer;
}

.smallIcon {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  margin-right: 16px;
  cursor: pointer;
}

.link {
    font-size: 16px;
    margin-right: 40px;
    color: black;
    // border-bottom: 3px solid $secondary;
    padding-bottom: 21px;
}

.link:focus {
  border-bottom: 3px solid $secondary;
}

.link:hover {
  text-decoration: none;
  color: black;
}

.marginTop24 {
  margin-top: 24px;
}

.paddingLeft24 {
  padding-left: 24px;
}


// HELP MENU SECTION

.helpMenu {
  right: 8px;
  width: 343px;
  position: absolute;
  top: 66px;
  // height: 414px;
  z-index: 20;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
}

.helpContainer {
  padding: 24px;
}

.helpTitle {
  font-size: 21px;
  color: $primary-dark;
  font-weight: 700;
}

.helpSubtitle {
  margin-bottom: 24px;
  line-height: 18px;
  font-size: 13px;
  color: $grey-dark;
}

// PROFILE MENU SECTION

.profileMenu {
  right: 8px;
  width: 343px;
  position: absolute;
  top: 66px;
  z-index: 20;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  padding-top: 24px;
  padding-bottom: 24px;
}

.profileMenuIcons {
  display: block; 
  margin-top: 26px; 
  color: #FF005F; 
  padding-left: 24px;

  &:hover {
    color: #FF005F;
  }
}

.profileMenuLineBreak {
  height: 1px; 
  background: #F7F7F7; 
  margin-top: 24px;
  margin-bottom: 24px;
}

.usersIcon {
  height: 20px;
  width: 20px;
  margin-right: 4px;
  path {
    fill: $secondary;
  }
}

.logoutText {
  display: block;
  color: #6C727E;
  padding-left: 24px;
  cursor: pointer;
}

.logoutIcon {
  height: 20px;
  width: 20px;
  margin-right: 4px;
  path {
    fill: $grey-dark;
  }
}

@media screen and (max-width: 1169px) {
    .desktopNavbar {
      display: none;
    }
  }
.activation {
    body {
        font-family: "Open Sans", sans-serif;
    }
    .container {
        margin-top: 5vh;
    }
    a {
        color: #32beaa;
    }
    h1 {
        font-weight: 300;
        font-size: 2.8rem;
        margin-bottom: 20px;
        line-height: normal;
        color: #000000;
    }
    h2 {
        font-weight: 300;
        font-size: 2.4rem;
    }
    h3 {
        font-weight: 300;
        font-size: 2.0rem;
    }
    h4 {
        font-weight: 300;
        font-size: 1.8rem;
    }
    h5 {
        font-weight: 300;
        font-size: 1.3rem;
        line-height: 1.9rem;
    }
    .jumbotron {
        background-color: #ffffff;
    }
    .description-txt {
        margin-top: 5vh;
    }
    #back {
        padding-top: 10px;
    }
    .btn {
        height: 38px;
        padding: 0 30px;
        font-size: 11px;
        font-weight: 600;
        line-height: 38px;
        text-transform: uppercase;
        border-radius: 4px;
        box-shadow: none;
        margin-top: 20px;
    }
    .btn-primary {
        color: #ffffff;
        background-color: #32beaa;
        border-color: #32beaa;
    }
    .btn-primary:hover {
        background-color: #32beaa;
        border-color: #32beaa;
        opacity: 0.8;
    }
    .btn-primary:active {
        background-color: #32beaa;
        border-color: #32beaa;
    }
    .btn-primary:focus,
    .btn-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 190, 170, 0.5);
    }
    .btn-primary.disabled,
    .btn-primary:disabled {
        color: #fff;
        background-color: #32beaa;
        border-color: #32beaa;
    }
    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show>.btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #30ab99;
        border-color: #30ab99;
    }
    .btn-link {
        color: #32beaa;
        text-transform: unset;
        font-size: 0.9rem;
        padding: 0px;
        font-weight: 400;
        line-height: 21px;
    }
    .btn-link:hover {
        color: #444444;
    }
    .btn-circle {
        color: #ffffff;
        background-color: #32beaa;
        border-color: #32beaa;
        width: 120px;
        height: 120px;
        padding: 10px 16px;
        border-radius: 60px;
    }
    .btn-circle-txt {
        overflow: hidden;
        white-space: pre-line;
        line-height: 16px;
        text-align: center;
        padding-top: 40%;
        padding-bottom: 40%;
    }
    .btn-outline-primary {
        color: #32beaa;
        border-color: #32beaa;
    }
    .btn-outline-primary:hover {
        background-color: #32beaa;
        border-color: #32beaa;
    }
    .btn-outline-primary:focus,
    .btn-outline-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 190, 170, 0.5);
    }
    .btn-outline-primary.disabled,
    .btn-outline-primary:disabled {
        color: #32beaa;
        background-color: transparent;
    }
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show>.btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: #30ab99;
        border-color: #30ab99;
    }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 190, 170, 0.5);
    }
    .formDescriptionTxt {
        margin-top: 10px;
        font-size: 14px;
    }
    .formName {
        margin-top: 16px;
    }
    .bg-homeit {
        background-color: #32beaa;
    }
    .alert-homeit {
        color: #0c5460;
        background-color: rgba(50, 190, 170, 0.2);
        border-color: rgba(50, 190, 170, 0.4);
    }
    .activation-code {
        font-size: 2rem;
        font-weight: 600;
        padding-top: 20px;
    }
    .card {
        background: #fff;
        margin-top: 20px;
        padding: 24px;
        border-radius: 20px;
        box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.08);
    }
    .card-title {
        font-weight: 500;
    }
    .wrapper-icon {
        padding: 0 20px 1vh 20px;
    }
    .activation-owner-text {
        font-size: 1.6rem;
    }
    /* Loading Spinner */
    .loader,
    .loader:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
    .loader {
        margin: 60px auto;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border-top: 1.1em solid rgba(10, 10, 10, 0.2);
        border-right: 1.1em solid rgba(10, 10, 10, 0.2);
        border-bottom: 1.1em solid rgba(255, 255, 255, 0.8);
        border-left: 1.1em solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    /* Loading Modal Css */
    .w3-modal {
        z-index: 3;
        padding-top: 100px;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.4)
    }
    .w3-modal-content {
        margin: auto;
        background-color: #fff;
        position: relative;
        padding: 0;
        outline: 0;
        max-width: 600px
    }
    .w3-card-4,
    .w3-hover-shadow:hover {
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19)
    }
    .w3-animate-zoom {
        animation: animatezoom 0.3s
    }
    @keyframes animatezoom {
        from {
            transform: scale(0)
        }
        to {
            transform: scale(1)
        }
    }
    .w3-center {
        display: block;
        width: auto;
        text-align: center;
        padding-bottom: 40px;
    }
    .activation-card {
        padding: 20px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        background-color: #f7f7f7;
    }
    .activation-card-margin {
        margin-left: 24px;
    }
    .activation-card-title {
        /* font-weight: 600; */
        min-height: 57px;
    }
    .activation-card-main-title {
        font-weight: 900;
    }
    .six.columns {
        width: 48%;
    }
    .email-form-close-button {
        position: absolute;
        top: 6px;
        right: 10px;
        cursor: pointer;
    }
    @media only screen and (max-width: 800px) {
        .six.columns {
            width: 100%
        }
        .activation-card-margin {
            margin-left: 0px;
        }
    }
    .center {
        text-align: center;
    }
    /* Code Success  */
    .code-success-title {
        font-size: 2.6rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .code-success-subtitle {
        font-size: 1.2rem;
    }
    .code-success-subtitle-green {
        color: #32beaa;
    }
    .code-success-subtitle-bold {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .activation-warning-message {
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-weight: 400;
        background: yellow;
    }
    .wrapper--first-row {
        display: flex;
        min-height: 100px;
        margin-top: 15px;
    }
    .wrapper--photo,
    .img--location {
        width: 40px;
        height: 40px;
        margin: 0 auto;
        display: block;
        // background-image: url('../../images/icon-location.svg');
        background-size: 100%;
    }
    .text--guest-tel {
        margin-bottom: .8em;
        color: #32beaa;
    }
    .u-full-width {
        height: 38px;
        padding: 6px 10px;
        background-color: #fff;
        border: 1px solid #D1D1D1;
        border-radius: 4px;
        box-shadow: none;
        box-sizing: border-box;
        width: 100%;
    }
    .activation-card-right {
        margin-left: 18px;
    }
    .row-margin-top {
        margin: 0px;
        margin-top: 20px;
    }
    .three-columns {
        width: 22%;
        margin-left: 4%;
    }
    .six-columns {
        width: 48%;
    }
    .twelve-columns {
        width: 100%;
    }
    .lock-img {
        width: 150px;
        mix-blend-mode: multiply;
    }
    .btn-choose {
        width: 40px;
        height: 40px;
        margin: 0 auto;
        display: block;
        cursor: pointer;
    }
    @media screen and (max-width: 800px) {
        .activation-card-right {
            margin-left: 0px;
        }
        .activation-card-responsive {
            margin: 0% 5%;
        }
        .lock-img {
            width: 150px;
            mix-blend-mode: multiply;
            display: block;
            margin: auto;
        }
    }
    @media screen and (max-width: 550px) {
        .three-columns {
            width: 100%;
            margin: 0px;
            margin-top: 14px;
        }
        .six-columns {
            width: 100%;
            margin: 0px;
        }
        .button-margin-bottom {
            margin-bottom: 100px;
        }
        .home-title-responsive {
            font-size: 2.0rem;
        }
    }
    .replace-devices__title {
        margin-bottom: 0px;
        margin-left: 20px;
        line-height: 100px;
        color: #fff;
    }
    .replace-devices__grid {
        display: grid;
        grid-template-columns: 45px auto 110px;
        background: #f7f7f7f7;
        padding: 1em;
        border-bottom: 1px solid #d3d3d3;
    }
}
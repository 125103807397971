@import '../../../variables';

.permission {
    &Container {
        padding-left: 24px;
        padding-top: 24px;
        padding-right: 72px;
    }

    &Subtitle {
        color: $grey-dark;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 100;
        line-height: 22px;
    }

    &Note {
        color: $grey-dark;
        margin-bottom: 40px;
        font-size: 12px;
        font-weight: 100;
        line-height: 18px;
    }

    &NoteLink {
        // color: $grey-dark;
        // margin-bottom: 40px;
        font-size: 12px;
        font-weight: 100;
        line-height: 18px;
    }

    &ButtonContainer {
        height: 48px;
        width: 220px;
        margin: auto;
        margin-top: 40px; 
    }

    &Button {
        height: 48px;
        width: 220px;
    }
}

.grid {
    display: grid;
    grid-template-columns: 48px 1fr;
    width: 246px;
    margin: auto;
    margin-top: 10px;

    &Text {
        font-size: 24px;
        font-weight: 500;
        line-height: 48px;
        margin-left: 10px;
        color: #FF005F;
    }
}
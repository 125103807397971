  .MessageFullPageContainer {
      position: relative;
      height: 80vh;
    }
  
  .MessageAbsoluteContainer {
      position: absolute;
      top: 0; 
      bottom: 0; 
      left: 0; 
      right: 0;
      margin: auto;
      height: 220px;
    }
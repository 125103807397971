@import '../../../variables';

.permission {
    &Container {
        padding-left: 24px;
        padding-top: 24px;
        padding-right: 72px;
    }

    &Subtitle {
        color: $grey-dark;
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: 100;
        line-height: 22px;
    }

    &ButtonContainer {
        height: 48px;
        width: 220px;
        margin: auto;
        margin-top: 40px; 
    }

    &Button {
        height: 48px;
        width: 220px;
    }
}
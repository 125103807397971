@import '../../../variables';
// all this css is just temporary and to get structure. All fine-tuning like colors, sizes, spacing.. will be provided with the final design

.container {
    background: #fff;
    margin-top: 20px;
    padding: 15px 25px;
    border-radius: 20px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
}

.key {
    border-radius: 20px;
    padding: 12px 16px;
    display: block;
    background: white;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.progressBar {
    height: 1px;
    /* Can be anything */
    position: relative;
    background: #f1f1f1;
    border-radius: 5px;
    span {
        display: block;
        height: 100%;
        border-radius: 5px;
        padding: 1px;
        background-color: $primary;
        box-shadow: 0px 0px 3px 0px #53f0d7;
        position: absolute;
        margin-top: -0.5px;
        // overflow: hidden;
    }
}

.code {
    color: $primary;
    font-weight: 700;
    cursor: pointer;
    margin-right: 8px;
}

.doors {
    color: #8b8b8b;
    font-size: 13px;
}

.checkIn,
.checkOut {
    font-size: 13px;
    line-height: 18px;
    margin-top: 5px;
}

.checkOut {
    text-align: right
}

.icon {
    background: url(../../../assets/images/filled/guest2.svg);
    background-size: 34px;
    background-repeat: no-repeat;
    margin-right: 8px;
}

.managerIcon {
    background: url(../../../assets/images/filled/manager.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 8px;
}

.serviceIcon {
    background: url(../../../assets/images/filled/services.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 8px;
}

.iconSms,
.iconEdit,
.iconDelete,
.iconRecover {
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(15%) sepia(89%) saturate(7482%) hue-rotate(332deg) brightness(99%) contrast(105%);
    cursor: pointer;
    height: 30px;
    width: 30px;
    position: relative;
    display: block;
    margin-left: 7px;
}

// .iconPropertyView  {
//     background-size: 55% !important;
// }
.iconEdit {
    background-image: url(../../../assets/images/lined/new.svg);
}

.iconSms {
    background-image: url(../../../assets/images/lined/lock-SMS.svg);
}

.iconDelete {
    background-image: url(../../../assets/images/lined/bin.svg);
}

.iconRecover {
    background-image: url(../../../assets/images/lined/history.svg);
}

.iconShare {
    background-image: url(../../../assets/images/solid/share.svg);
    background-size: 21.43px;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(15%) sepia(89%) saturate(7482%) hue-rotate(332deg) brightness(99%) contrast(105%);
    cursor: pointer;
    height: 21.43px;
    width: 21.43px;
    position: relative;
    display: block;
    margin-left: 7px;
}

.iconChevronDown {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
}

.iconEyeShow,
.iconEyeHide {
    background-size: 25px;
    background-repeat: no-repeat;
    background-position-x: center;
    cursor: pointer;
}

.iconEyeShow {
    background-image: url(../../../assets/images/solid/eye.svg);
}

.iconEyeHide {
    background-image: url(../../../assets/images/solid/eye-crossed.svg);
}

.newKeyMessage {
    margin: auto;
    margin-top: -25px;
    text-align: center;
    background: #FF005F;
    max-width: 60px;
    font-weight: 100;
    color: #fff;
}

.firstColumn {
    display: none;
}

.copyTokenAlert {
    text-align: center;
    top: 30px;
    position: absolute;
    left: 0;
    right: 0;
}

.passcodeWrapper {
    margin-top: 7px;
    display: flex;
    justify-content: flex-end;
    // margin-right: 10px;
}

.cardContainerKeys {
    display: flex;
}

.cardSubContainerKeys {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.iconsContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div:first-child {
        margin-left: 0;
    }
}

.keyInfoContainer {
    max-width: 60%;
}

@media (max-width: 480px) {
    .keyInfoContainer {
        max-width: 200px;
        display: block;
        overflow: hidden;
        position: relative;
    }
    .guestName {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

@media (max-width: 410px) {
    .keyInfoContainer {
        max-width: 150px;
    }
}

@media (max-width: 370px) {
    .keyInfoContainer {
        max-width: 100px;
    }
}

@media (min-width: 1000px) {
    .iconSms,
    .iconEdit,
    .iconDelete,
    .iconRecover,
    .iconShare {
        margin-left: 24px;
    }
    .iconSms,
    .iconEdit,
    .iconDelete,
    .iconRecover {
        background-size: 35px;
    }
    .iconEyeShow,
    .iconEyeHide {
        background-size: 30px;
    }
    // .passcodeWrapper {
    //     margin-right: 10px;
    // }
    .guestName {
        font-size: 21px;
        overflow: visible;
    }
    .icon,
    .managerIcon,
    .serviceIcon {
        background-size: 40px;
        background-position: top;
        padding-right: 0px !important;
    }
    .keyCardContainer {
        margin: 0px !important;
    }
    .passCodeContainer {
        text-align: center;
    }
    .key {
        padding: 24px;
    }
}

@media (min-width: 800px) {
    .firstColumn {
        display: block;
    }
    .copyTokenAlert {
        text-align: center;
        top: 40px;
        position: absolute;
        left: 0;
        right: 0;
    }
}

.keyContainer {
    margin-top: 20px;
}

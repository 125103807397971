@import '../../variables';

//NAVBAR - move to component
nav {
    // background-color: $primary;
    padding: 15px;

    background {
      width: 100%;
      background: $primary-dark;
      opacity: 0.8;
      height: 100%;
      position: absolute;
      z-index: 20;
      top: 0;
      right: 0;
      display: none;

      &.show{
        display: block;
      }
    }
}
/* The side navigation menu */
.sidenav {
    height: 100%; /* 100% Full-height */
    width: 75%; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 20; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    // padding: 24px;
    background-color: $white; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 24px; /* Place content 60px from the top */
    transform: translateX(-100%);
    transition: transform 0.35s; /* 0.5 second transition effect to slide in the sidenav */
    max-width: 400px;
    
    &.show{
      transform: translateX(0)
    }
    // Applying general padding
    > div {
      padding: 24px;
      padding-top: 0;
    }

    a {
      padding-bottom: 24px;
      font-size: 13px;
      font-weight: 600;
      line-height: 22px;
      text-decoration: none;
      display: block;
      transition: 0.3s;
      color: $secondary
      }
      
      .centerSection a {
        color: $primary-dark; 
        padding-left: 24px;
        font-size: 16px;
      }
      
      a:hover {
        color: #f1f1f1;
    }

    .closebtn {
      position: absolute;
      top: 20px;
      right: 15px;
      margin-left: 50px;
      width: 40px;
      height: 40px;
      background: url('../../assets/images/lined/close.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }

    hr {
      border: 1px solid #F7F7F7;
      margin-left: -24px;
      margin-right: -24px;
    }

}

#navBarIcon {
  background-image: url('../../assets/images/lined/hamburger.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 36px;
  filter: invert(1);
  margin-left: -4px;
}

.upgradeBtn {
  width: 180px !important;
  font-size: 13px
}

.navbarLink {
  color: black;
  font-size: 0.4rem;

}

.settingsIcon{
  width: 20px;
  height: 20px;
  margin-right: 4px;
  path {
    fill: $secondary;
  }
}

.bottomSection{
  a {
    svg {
      margin-right: 4px;
      width: 20px;
      height: 20px;
      path {
        fill: $secondary;
      }
    }

    &.logoutIcon{
      color: $grey-dark;
      path {
        fill: $grey-dark;
      }
    }
  }
}
  
/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

@media screen and (min-width: 1170px) {
  .responsiveNavbar {
    display: none;
  }
}

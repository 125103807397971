.ldsSpinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 78px;
    height: 78px;
    margin-top: 14px;
    margin-left: 18px;
}

.ldsSpinner div {
    transform-origin: 32px 32px;
    animation: ldsSpinner 1.2s linear infinite;
}

.ldsSpinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 18px;
    border-radius: 35%;
    background: black;
}

.ldsSpinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.ldsSpinner div:nth-child(2) {
    transform: rotate(45deg);
    animation-delay: -1s;
}

.ldsSpinner div:nth-child(3) {
    transform: rotate(90deg);
    animation-delay: -0.9s;
}

.ldsSpinner div:nth-child(4) {
    transform: rotate(135deg);
    animation-delay: -0.8s;
}

.ldsSpinner div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.7s;
}

.ldsSpinner div:nth-child(6) {
    transform: rotate(225deg);
    animation-delay: -0.6s;
}

.ldsSpinner div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.5s;
}

.ldsSpinner div:nth-child(8) {
    transform: rotate(315deg);
    animation-delay: -0.4s;
}

.ldsSpinner div:nth-child(9) {
    transform: rotate(360deg);
    animation-delay: -0.3s;
}

// .ldsSpinner div:nth-child(10) {
//   transform: rotate(270deg);
//   animation-delay: -0.2s;
// }
// .ldsSpinner div:nth-child(11) {
//   transform: rotate(300deg);
//   animation-delay: -0.1s;
// }
// .ldsSpinner div:nth-child(12) {
//   transform: rotate(330deg);
//   animation-delay: 0s;
// }
@keyframes ldsSpinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.SpinnerFullPageContainer {
    position: relative;
    height: 100vh;
    width: 100vw
}

.SpinnerAbsoluteContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 160px;
}
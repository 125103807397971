@import '../../../variables';

.header {
    display: block;
    background-color: #FFFFFF;
    padding: 25px;
    padding-top: 0px;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    flex-wrap: wrap;
}

.card,
.cardMini {
    background: #fff;
    margin-top: 20px;
    padding: 24px 0;
    border-radius: 20px;
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.08);
    flex-wrap: wrap;
}

.iconText {
    line-height: 30px;
    display: inline-block;
    font-size: 16px;
}

.icon {
    margin-right: 6px;
    height: 26px;
    width: 26px;
}

.btnCreateKey {
    background-image: url('../../../assets/images/lined/key-create_white.svg');
    background-color: #FF005F;
    box-shadow: 0 7px 8px -2px rgba(255, 0, 95, 0.38);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 50% 50%;
    width: 34px;
    height: 34px;
    display: block;
    border-radius: 50%;
    float: right;
}

.btnEditProperty {
    background-image: url('../../../assets/images/lined/new.svg');
    filter: invert(15%) sepia(89%) saturate(7482%) hue-rotate(332deg) brightness(99%) contrast(105%);
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: 50% 50%;
    width: 34px;
    height: 34px;
    display: block;
    float: right;
    margin-right: 8px;
}

.lowBattery {
    &Icon {
        width: 32px;
        height: 24px;
        position: relative;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        path {
            fill: $warning;
        }
    }
    &Text {
        color: $warning;
    }
}

.boxOffline {
    &Icon {
        width: 32px;
        height: 24px;
        position: relative;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        path {
            fill: $warning;
        }
    }
}

.linksFlex {
    display: flex;
    flex-direction: row-reverse;
    padding: 0px;
}

.doorContainer {
    margin-top: 32px;
    padding: 0 15px;
}

.detailsContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media only screen and (min-width: 900px) {
        flex-direction: row;
    }
}

.desktopCardContainer {
    margin: 32px 0;
}

.titlePaddingTop {
    padding-top: 0px;
}

.iconName {
    display: none;
}

.iconContainerMargin {
    margin: 0px;
    margin-top: 15px;
}

.propertyDetailsRow{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.cardMini {
    display: flex !important;
}

.propertyMiniDesktop {
    display: none !important;
}

.desktopCardContainer {
    padding: 0px !important;
}

.settingsProperty {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
}

.activityItem {
    overflow: hidden;
}

@media only screen and (min-width: 900px) {
    .header {
        margin-top: 20px;
        padding-top: 20px;
        border-radius: 20px;
        background: white;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    }
    .card {
        background: none;
        box-shadow: none;
        padding: 0px !important;
        margin-top: 42px !important;
    }
    .doorContainer {
        margin-top: 16px;
        margin-bottom: 16px;
        padding: 24px 40px;
        background: #fff;
        border-radius: 20px;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    }
    .desktopCardContainer {
        min-height: 762px;
        border-radius: 16px;
        padding: 25px !important;
        background-color: #FFFFFF;
        margin-top: 0px;
        max-width: 48.5% !important;
        box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.08);
        overflow: hidden;
        &:not(:last-child) {
            margin-right: 16px;
        }
    }
    .bookingsCardContainer {
        min-height: 190px;
        margin-top: 16px;
        border-radius: 16px;
        padding: 25px !important;
        background-color: #FFFFFF;
        box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.08);
    }
    .titlePaddingTop {
        // padding-top: 32px !important;
        padding: 5px 0px;
        margin: 32px 0 0 0;
    }
    .btnEditProperty {
        width: 40px;
        height: 40px;
        margin-right: 24px;
    }
    .btnCreateKey {
        width: 40px;
        height: 40px;
    }
    .iconContainer,
    .iconContainerMargin {
        margin: auto;
    }
    .icon {
        margin-right: 8px;
        height: 32px;
        width: 32px;
    }
    .iconName {
        display: inline-block;
        text-transform: lowercase;
        margin-left: 5px;
    }
    .linksFlex {
        margin: auto;
    }
    .cardMini {
        display: none !important;
    }
    .propertyMiniDesktop {
        display: block !important;
    }
    .settingsProperty {
        flex-direction: row;
    }
}
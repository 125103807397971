.modal {
    &Background {
        position: fixed;
        z-index: 100;
        height: 100vh;
        width: 100vw;
        left: 0px;
        right: 0px;
        top: 0px;
        opacity: 1;
        background-color: #0A1428;
    }

    &Card {
        min-height: 290px;
        max-width: 504px;
        margin: 16px;
        // margin-top: 48px;
        border-radius: 16px;
        background-color: #FFFFFF;
    }

    &CloseButton {
        opacity: 1;
        height: 40px;
        margin: 15px;
        cursor: pointer;
    }
}

@media only screen and (min-width: 600px) {
    .modalCard {
        margin: auto;
        margin-top: 48px;
    }
}
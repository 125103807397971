@import '../../variables';
// .header {
//     height: 80px;
//     margin: -24px;
//     padding: 24px;
//     border-top-left-radius: 20px;
//     border-top-right-radius: 20px;
//     margin-bottom: 25px;
//     background-image: linear-gradient(white 0px, white 70%, #f3f3f3 100%);
// }
.questionMark {
    line-height: 20px !important;
    display: inline-block;
    margin-left: 6px;
    border-radius: 50%;
    // border: 1.5px solid $primary;
    width: 20px;
    height: 20px;
    text-align: center;
    font-weight: 600;
    color: $primary;
    cursor: pointer;
    font-size: 16px;
}

///// copied from property css
.titlePaddingTop {
    // padding-top: 32px !important;
    padding: 5px 0px;
    margin: 32px 0 0 0;
}

.header {
    display: block;
    background-color: #FFFFFF;
    padding: 25px;
    padding-top: 0px;
    border-radius: 20px 20px 0px 0px;
}

.card,
.cardMini {
    background: #fff;
    margin-top: 20px;
    padding: 24px;
    border-radius: 20px;
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.08);
    max-width: 373px;
}

.doorContainer {
    margin-top: 32px;
}

@media only screen and (min-width: 900px) {
    .header {
        margin-right: -24px;
        margin-left: -24px;
        margin-top: 20px;
        padding-top: 20px;
        border-radius: 20px;
        display: block;
        background: white;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    }
    // .card {
    //     background: none;
    //     box-shadow: none;
    //     padding: 0px !important;
    //     margin-top: 32px !important;
    // }
    // .cardMini {
    //     display: none !important;
    // }
}

//////// copied from navbar desktop css
.desktopNavbar {
    height: 66px;
    background: #fff;
    padding: 0px 4%;
    line-height: 66px;
}

.logo {
    height: 22px;
    width: 130px;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    // margin-right: 79px;
    cursor: pointer;
}

.tutorialButton {
    // height: 22px;
    width: 130px;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    // margin-right: 79px;
    cursor: pointer;
}

.languageSelect {
    // height: 22px;
    // width: 130px;
    display: inline-block !important;
    vertical-align: middle;
    height: 100%;
    // margin-right: 79px;
    cursor: pointer;
}

/////// copied from key css
.iconShare {
    background-image: url(../../assets/images/solid/share.svg);
    background-size: 21.43px;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(15%) sepia(89%) saturate(7482%) hue-rotate(332deg) brightness(99%) contrast(105%);
    cursor: pointer;
}

.copyTokenAlert {
    text-align: center;
    top: 30px;
    position: absolute;
    left: 0;
    right: 0;
}
// Primary Colors
$primary-dark:          #0A1428; 
$primary:               #00B7A0;
$primary-light:         #D8F1EC;

// Secondary Colors
$secondary:             #FF005F;
$secondary-light:       #FFD4DF;

// Button Colors
$success:               #007E6E;
$info:                  #007583;
$error:                 #DB0000;
$warning:               #FFB733;

// Neutral Colors
$grey-dark:             #6C727E;
$grey:                  #9DA1A9;
$grey-light:            #CED0D4;

$light:                 #f7f7f7;
$white:                 #FFF;

$body-bg:          $light !default;

$font-family-base: 'Hind Madurai';

@import '../../variables';

.card {
    background: #fff;
}

.header {
    display: block;
    // background-image: linear-gradient(white 0px, white 70%, #fbfbfb 100%);
    border-radius: 20px 20px 0px 0px;
}

@media only screen and (max-width: 900px) {
    .card {
        margin-bottom: 18px !important;
    }
}

@import '../../variables';

.card {
    background: #FFF;
    margin-top: 8px;
    padding: 16px;
    min-height: 101px;
    border-radius: 20px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
}

.synchronisation__subtitle {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 100;
}

.synchronisation__content {
    font-size: 15px;
    color: #FFF;
}

.marginTop30 {
    margin-top: 30px;
}

.color_black {
    color: #000;
}

.bold {
    font-weight: 700;
}

.cardDescription {
    font-size: 13px;
    line-height: 18px;
    color: $grey-dark;
}

.iconDeleteProperty{
    background-image: url('../../assets/images/lined/bin.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    height: 100%;
    background-position-y: 45%;
    background-position-x: 50%;
    margin-left: -15px;
}

.btnCreateIcal {
    background-image: url('../../assets/images/lined/plus-white.svg');
    background-color: #FF005F;
    box-shadow: 0 7px 8px -2px rgba(255,0,95,0.38);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 50% 50%;
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    float: right;
}

@media only screen and (min-width: 1000px) {
    .doubleButtonContainer {
        width: 439px;
        margin: auto;
    }
}

.formSection {
    @media only screen and (min-width: 1000px) {
        display: flex;
        justify-content: space-between;

        > div {
            width: 48.5%;
        }
    }
}
.inputsLabel {
    font-size: 14px;
}

.itemInfo {
    color: #9DA1A9;
}

.syncHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > button {
        display: flex;
        align-items: center;
        cursor: pointer;
        background: none;
        border: none;
        outline: none!important;
        
        svg {
            width: 20px;
            height: 20px;
            margin-right: 4px;

            path {
                fill: $secondary;
            }
        }
        span {
            color: $secondary;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 16px;

            @media only screen and (min-width: 900px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}
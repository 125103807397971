@import './_variables.scss';
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
// FONTS
h1 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    color: #fff;
}

h2 {
    font-family: 'Hind Madurai', sans-serif;
    font-weight: 700;
    font-size: 21px;
    line-height: 29px;
}

h3 {
    font-family: 'Hind Madurai', sans-serif;
    font-weight: 700;
    line-height: 22px;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
}

body {
    font-family: 'Hind Madurai', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: $primary-dark;
}

p, a, label {
    font-family: 'Hind Madurai', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    margin: 0;
}

span {
    font-family: 'Hind Madurai', sans-serif;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
}

.form-control {
    border-radius: 8px;
    height: 45px;
}

.form-control-search {
    background-color: $white;
    border: none;
    box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
    padding: 9px 39px;
    &::placeholder {
        color: $grey-light;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: $grey-light;
    }
    &::-ms-input-placeholder {
        color: $grey-light;
    }
}

.search-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    transform: translateY(-50%);
    left: 23px;
    path {
        fill: $grey-light;
    }
}

input[type="checkbox"] {
    width: 25px;
    height: 25px;
}

.btn {
    border-radius: 8px;
    box-shadow: 0px 6px 10px 0px #ff005f47;
    height: 45px;
    font-size: 13px; //TEMPORARY 
    &.btn-cancel {
        background: white;
        border: none;
        color: $secondary;
        box-shadow: none;
        font-weight: 600;
    }
    &.btn-light {
        background: $secondary-light;
        border: none;
        color: $secondary;
        box-shadow: none;
        font-weight: 600;
        // width: 100%,
    }
    &.btn__no-background {
        background: none;
    }
    &.btn-w20 {
        min-width: 20%
    }
    &.btn-mr20 {
        margin-right: 20px;
    }
    &:disabled {
        background-color: #CED0D4;
        border-color: #CED0D4;
        color: #9DA1A9;
        box-shadow: none;
    }
}

input[type="radio"] {
    width: 25px;
    height: 25px;
}

.react-calendar {
    border: none;
    border-radius: 8px;
    box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    padding: 8px 11px;
    width: 100%;
    // max-width: 500px;
    .react-calendar__month-view__weekdays__weekday {
        color: rgb(179, 179, 179);
        font-weight: normal;
    }
    abbr[title] {
        text-decoration: none;
    }
    .react-calendar__tile--active {
        background-color: #f7d3df;
        color: #ff005f;
        // height: 35px;
        // margin-top: 5px;
        // margin-bottom: 5px;
        // line-height: 0;
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: #f7d3df;
    }
    .react-calendar__tile--rangeStart,
    .react-calendar__tile--rangeEnd {
        // border-radius: 50%;
        background-color: #f7d3df;
        // font-weight: bold;
        // height: 41.6px;
        // margin-top: 0;
        // transform: scale(1.2);
    }
    .react-calendar__tile:disabled {
        background-color: white;
        color: rgb(236, 236, 236);
    }
}

.black {
    color: black;
}

.alignDiv {
    background: white;
    width: 200px;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.text-grey-dark {
    color: $grey-dark;
}

#listContainer {
    // padding: 25px 0px;
    &::after {
        // background-color: $primary;
        background: linear-gradient(135.05deg, #85D4C7 0%, #00B7A0 100%);
        position: absolute;
        width: 100%;
        height: 206px;
        top: 0;
        left: 0;
        z-index: -1;
        content: "";
        border-radius: 0px 0px 25px 25px;
        background-origin: border-box;
        background-clip: content-box, border-box;


        @media (min-width: 1200px) {
            height: 264px;
        }
    }
}

#listContainerBig {
    // padding-top: 32px;
    &::after {
        height: calc(66px + 220px);
        background: linear-gradient(135.05deg, #85D4C7 0%, #00B7A0 100%);
        position: absolute;
        width: 100%;
        height: 264px;
        top: 0;
        left: 0;
        z-index: -1;
        content: "";
        border-radius: 0px 0px 25px 25px;
        background-origin: border-box;
        background-clip: content-box, border-box;
    }
}

#listContainerFull {
    position: relative;
    &::after {
        height: calc(66px + 220px);
        background: linear-gradient(135.05deg, #85D4C7 0%, #00B7A0 100%);
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        top: 0;
        left: 0;
        z-index: -1;
        content: "";
        background-origin: border-box;
        background-clip: content-box, border-box;
    }
}

.fullPageBackground {
    min-height: calc(100vh - 66px);
    // background: #00B7A0;
}

.keyHighlight {
    animation-name: key-highlight-animation;
    animation-duration: 2s;
}

@keyframes key-highlight-animation {
    0% {
        background: #fff;
    }
    50% {
        background: #ffb733;
    }
    100% {
        background: #fff;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1024px;
    }
    .font-size-21 {
        font-size: 21px;
    }
}

@media (min-width: 1170px) {
    #listContainer {
        padding-top: 32px;
    }
    #listContainerBig {
        padding-top: 32px;
        &::after {
            height: calc(66px + 230px);
            background: linear-gradient(135.05deg, #85D4C7 0%, #00B7A0 100%);
            border-radius: 0px 0px 32px 32px;
        }
    }
    h1 {
        font-size: 28px;
    }
    .button-container {
        max-width: 439px;
        margin: auto;
    }
}

.medium-container {
    max-width: 677px;
    margin: auto;
}

.font-grey {
    color: $grey-dark;
}


/***************************************************************************
    Styling Checkbox Starts 
***************************************************************************/

.checkbox-grid {
    display: grid;
    grid-template-columns: 40px auto;
    height: 32px;
}

.checkbox-label {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    line-height: 24px;
    height: 24px;
    width: 24px;
    clear: both;
}

.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 1px solid $grey-light;
}

.checkbox-label input:disabled~.checkbox-custom {
    background-color: $grey-light;
    border-radius: 5px;
    border: 1px solid $warning;
}

.checkbox-label input:checked~.checkbox-custom {
    background: $secondary;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 2px solid #FFFFFF;
}

.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid $secondary;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}

.checkbox-label input:checked~.checkbox-custom::after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    background-color: transparent;
    border-radius: 0;
}

.checkbox-text {
    padding-top: 2px;
}


/* Style for Circular Checkbox */

.checkbox-label .checkbox-custom.circular {
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.checkbox-label input:checked~.checkbox-custom.circular {
    background-color: #FFFFFF;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.checkbox-label input:checked~.checkbox-custom.circular::after {
    border: solid #0067FF;
    border-width: 0 2px 2px 0;
}

.checkbox-label .checkbox-custom.circular::after {
    border-radius: 50%;
}

.checkbox-label .checkbox-custom.circular::before {
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.checkbox-label input:checked~.checkbox-custom.circular::before {
    border-radius: 50%;
}


/***************************************************************************
    Styling Checkbox Ends
***************************************************************************/


/***************************************************************************
    Styling Radio Checkbox Starts
***************************************************************************/


/* The container */

.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default radio button */

.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


/* Create a custom radio button */

.radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $white;
    border: 1px solid $grey-light;
    border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked~.radio-checkmark {
    background-color: white;
    border: 2px solid $secondary;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.radio-container input:checked~.radio-checkmark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.radio-container .radio-checkmark:after {
    top: 3px;
    left: 3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: $secondary;
}

/***************************************************************************
    Styling Radio Checkbox Ends
***************************************************************************/

.swal2-icon.swal2-info {
    border-color: transparent;
    color: transparent;
    width: 80px;
    height: 80px;
}

.swal2-container > div, .swal-modal {
    overflow: hidden;
}

.swal2-icon.swal2-info {
    border-color: transparent;
    color: transparent;
    width: 80px;
    height: 80px;
}

.popup-content {
    overflow: hidden;
    padding: 30px;
    border-radius: 16px;
    @media only screen and (max-width: 767px) {
        width: 100%;
    }
    @media only screen and (min-width: 768px) {
        max-height: 90vh;
        &.no-overflow-content {
            overflow: visible;
        }
        
    }

    .selection-container{
        max-height: 50vh;
        overflow-y: scroll; 
        -ms-overflow-style: none; 
        scrollbar-width: none; 
        &::-webkit-scrollbar {
            display: none;
        }

        @media only screen and (min-width: 768px) {
            overflow-y: visible; 
        }
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            font-size: 22px;
        }
        .close {
            cursor: pointer;
            outline: none;
        }
    }
}

.bw-40 {
    width: 40%;
    max-width: 250px;
}
.popup-overlay {
    overflow-y: scroll; 
}

.headerDescription {
    font-size: 15px;
    font-weight: 100;
    color: $white;
}

.selected-link {
    border-bottom: 3px solid $secondary;
}


//datepicker
.react-date-picker {
    &__button svg{
        width: 20px;
        height: 20px;
    }

    &__wrapper {
        display: flex;
        width: 150px;
        max-width: 150px;
        padding: 0.375rem 0.75rem;
        color: #495057;
        background-color: #FFF;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        max-height: 44px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &__inputGroup {
        color: #b9bfc5;
        /* &__input {
            color: #b9bfc5;
        } */
    }

    &__calendar {
        width: 230px;
        .react-calendar {
            font-size: 14px;
            padding: 8px 5px;

            &__tile {
                padding: 5px;
            }
            &__navigation {
                height: 24px;
                margin-bottom: 0.2rem;
                button {
                    min-width: 27px;
                }
            }
        }
    }
}

.no-padding {
    padding: 0;
}
.loading-wrapper{
    width: 48px;
    height: 48px;
    border-radius:50%;
    position:relative;
    display: inline-block;
    overflow:hidden;
}
.rotate{
    animation: rotate 1s linear infinite;
}
.circle{
    width:100%;
    height:100%;
    display:block;
    animation: grow 1.5s ease-in-out infinite;
    box-shadow: 0 0 4px 0 rgba(0,183,160,0.6);
}

.green {
    background-color:#00B7A0;
}
.gap-patch{
    position:absolute;
    margin:auto;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:90%;
    height:90%;
    border-radius:50%;
    background-color:white;
    color:black;
}

@keyframes grow{
    0%{
        width:8%;
    }
    45%{
        width: 90%;
    }
    55%{
        width: 90%;
    }
    95%{
        width:8%;
    }
    100%{
        width:8%;
    }
}

@keyframes rotate{
    0%{
         transform: rotateZ(0deg);
    }
    100%{
         transform: rotateZ(360deg);
    }
}
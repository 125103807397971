@import '../../../variables';

.iconDeleteProperty {
    height: 100%;
    margin-left: -15px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    svg {
        height: 30px;
        width: 30px;
        path {
            fill: $secondary;
        }
    }
}

.selectPropertyKey {
    margin: 24px 0;
}

.hasDelete {
    margin-top: 48px;
}

#addPropertyButton {
    background-color: #f7d3df;
    border: none;
    font-size: 14px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    margin-bottom: 48px;
}

.iconDelete {
    filter: invert(15%) sepia(89%) saturate(7482%) hue-rotate(332deg) brightness(99%) contrast(105%);
    height: 30px;
    width: 30px;
}

.titleTopPadding {
    padding-top: 32px;
}

.cardMarginTop {
    margin-top: 40px;
}

.responsiveTitlePadding {
    padding-left: 20px !important;
}

@media only screen and (max-width: 900px) {
    .titleTopPadding {
        padding-top: 0px;
    }
    .cardMarginTop {
        margin-top: 20px;
    }
    #wrapper {
        margin-top: 20px;
    }
    .responsiveTitlePadding {
        padding-left: 5px !important;
    }
}

.KeyExpiringSelector {
    padding-bottom: 40px;
    input {
        left: 0;
        z-index: 10;
    }
}

@media only screen and (min-width: 900px) {
    #addPropertyButton {
        max-width: 298px;
        margin: auto;
        margin-bottom: 64px;
    }
    .selectPropertyKey {
        margin: 30px 0;
    }
    .hasDelete {
        margin-top: 64px;
    }
}
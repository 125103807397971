@import '../../variables';

#container{
    // padding: 25px 0px;
    
    &::after{
        background-color: $primary;
        position: fixed;
        width: 100%;
        height: 250px;
        top:0; 
        left:0;
        z-index: -1;
        content: "";
        border-radius: 0px 0px 25px 25px;
        background-origin: border-box;
        background-clip: content-box, border-box;
    }

    h2 {
        font-weight: bold;
    }

    .profile {
        margin-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

    }
    .header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-image: linear-gradient(white 0px, white 70%, #f3f3f3 100%);
        padding-top: 30px;
        padding-bottom: 15px;
    }
}

.settingsEditIcon {
    background: url('../../assets/images/lined/new.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 36px; 
    width: 36px;
    
    path {
        fill: $secondary;
    }
}

input:focus {
    outline: none;
}
